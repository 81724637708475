.body-container {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.profileimg {
  height: 300px;
  width: 300px;
  border: 2px solid white;
  border-radius: 50%;
  object-fit: cover;
}
.title-para {
  margin: 5px auto;
  text-align: center;
}
.textme {
  text-decoration: none;
}
/* my work */
/* .work-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
} */
.work-img {
  border: 1px dotted white;
  border-radius: 3px;
  cursor: pointer;
  display: grid;
  grid-template-rows: 200px 150px;
}
@media screen and (max-width: 576px) {
  .work-img {
    grid-template-rows: 300px auto;
  }
  .work-detail {
    padding: 10px 0;
  }
}
.workmain-img {
  height: 100%;
  position: relative;
}
.visit-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #292828d1;
  padding: 5px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.3s ease;
}

.visit-btn.hovered {
  opacity: 1;
  visibility: visible;
}
.work-detail {
  text-align: left;
}
.work-name {
  border: 1px dotted #fff;
  padding: 3px;
  margin: 10px 0;
}
.tools {
  border: 1px dotted #fff;
  padding: 3px;
  margin-right: 4px;
  margin-top: 5px;
}
@media screen and (max-width: 560px) {
  .profileimg {
    height: 150px;
    width: 150px;
  }
}
.company-img {
  padding: 0.25rem;
  width: 100%;
}

/* ===carousel==== */
.quotes-color {
  color: #cc1616;
}
.img-profile {
  height: 150px;
  width: 40%;
  object-fit: contain;
}
.carousel-quotes {
  position: relative;
  width: 80%;
  margin: 20px auto;
}
.carousel-quotes-r {
  position: absolute;
  font-size: 30px;
}
.cq-l {
  top: -19px;
  left: 0;
}
.cq-r {
  bottom: -16px;
  right: 0;
}
.carousel-inner {
  padding: 23px !important;
}
/* ===skill=== */
.sk-img {
  height: 70px;
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 450px) {
  .sk-img {
    height: 50px;
  }
}
