/* ==golbal css=== */
.sub-grey {
  color: #7b7b7b;
}
.dotted-border {
  border: 1px dotted #fff;
}
.d-r {
  border-right: 1px dotted #fff;
}
.d-l {
  border-left: 1px dotted #fff;
}
.d-b {
  border-bottom: 1px dotted #fff;
}
.d-t {
  border-top: 1px dotted #fff;
}
/* /========= */
.logo {
  height: 50px;
}
.navbar-container {
  border-bottom: 1px solid #fff;
  background-color: black;
}
